import { ComponentProps, useEffect } from "react";

import { FlashType, useConfig } from "@envato/sso-common";

import { useToasts } from "../ToastsProvider";
import { Toast } from "@envato/design-system/components";

const FLASH_DURATION = 10;

type ToastVariant = ComponentProps<typeof Toast>["variant"];

const TYPE_TO_VARIANT: { [key in FlashType]: ToastVariant } = {
  notice: "info",
  warning: "caution",
  error: "critical",
  info: "info",
  confirmation: "positive",
};

const Flash = () => {
  const [{ flash }] = useConfig();

  const { showToast } = useToasts()!;

  useEffect(() => {
    Object.entries(flash).forEach(([type, message]) => {
      showToast({
        variant: TYPE_TO_VARIANT[type as FlashType],
        message,
        autoDismiss: FLASH_DURATION,
      });
    });
  }, [flash, showToast]);

  return null;
};

export default Flash;
