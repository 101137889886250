import React, {
  useState,
  useCallback,
  useContext,
  ComponentProps,
} from "react";

import { useConfig, useTranslation } from "@envato/sso-common";

import { Stack, Box, Toast } from "@envato/design-system/components";

const DEFAULT_TOAST_TIME = 1.5;

type ToastProps = ComponentProps<typeof Toast> & {
  id?: string;
  message: string;
};
type ToRemoveProps = Pick<ToastProps, "id"> & Pick<ToastProps, "onDismiss">;

type ToastProviderType = {
  showToast: (toShow: ToastProps) => void;
  removeToast: (toRemove: ToRemoveProps) => void;
};

const ToastContext = React.createContext<ToastProviderType | undefined>(
  undefined,
);

export const useToasts = () => useContext(ToastContext);

interface ToastsProviderProps {
  children?: React.ReactNode;
}

const ToastWrapper = ({ id, onDismiss, message, ...rest }: ToastProps) => {
  const { removeToast } = useToasts()!;
  const t = useTranslation();

  const onDismissWrapper = useCallback(
    () => removeToast({ id, onDismiss }),
    [id, onDismiss, removeToast],
  );
  return (
    <Box data-testid="toast" width={{ default: "fit-content", 400: "full" }}>
      <Toast {...rest} onDismiss={onDismissWrapper} data-testid="toast">
        {t(message)}
      </Toast>
    </Box>
  );
};

const ToastsProvider: React.FC<ToastsProviderProps> = ({ children }) => {
  const [toasts, setToasts] = useState<Array<ToastProps>>([]);
  const [{ environment }] = useConfig();

  console.log("Toasts", toasts);

  const showToast = useCallback(
    (toShow: ToastProps) => {
      const newToast: ToastProps = {
        id: `${toShow.variant}${Date.now()}${Math.random()}`,
        autoDismiss: DEFAULT_TOAST_TIME,
        dismissible: true,
        origin: "left",
        ...toShow,
      };

      setToasts((existing) => [...existing, newToast]);
    },
    [environment],
  );

  const removeToast = useCallback(({ id, onDismiss }: ToRemoveProps) => {
    setToasts((existing) => {
      return existing.filter((t) => t.id !== id);
    });

    if (onDismiss) {
      onDismiss();
    }
  }, []);

  return (
    <ToastContext.Provider value={{ showToast, removeToast }}>
      {children}
      <Box
        padding="none"
        position="fixed"
        bottom="none"
        left="none"
        right="none"
        margin="4x"
        zIndex="3"
        width={{ default: "fit-content", 400: "full" }}
        maxWidth="breakpoint-medium"
        colorScheme="light"
      >
        <Stack spacing="2x">
          {toasts.map(({ id, ...rest }) => (
            <ToastWrapper key={id} id={id} {...rest} />
          ))}
        </Stack>
      </Box>
    </ToastContext.Provider>
  );
};

export default ToastsProvider;
